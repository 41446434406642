<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
      <submenu-server menu="dinoedge"></submenu-server>

        <div class="submenu-content">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>

          <!-- show page content -->
          <div v-if="item">
            <div v-for="(b, index) in item.body">

              <!-- page title -->
              <div v-if="b.type === 'block'">
                <div v-if="b.value.heading === 'DinoEdge Scalable Real-time AI Platform'">
                  <div class="float-start title_img1" v-if="b.value.image">
                    <img :src="b.value.image.medium.src"
                    :width="b.value.image.medium.width"
                    :height="b.value.image.medium.height">
                  </div>
                  <div class="float-start title"><h2>{{b.value.heading}}</h2></div>
                  <div class="float-start" v-if="b.value.image2">
                    <img :src="b.value.image2.medium.src"
                    :width="b.value.image2.medium.width"
                    :height="b.value.image2.medium.height">
                  </div>
                  <div class="clear"></div>
                  <br/><br/>
                </div>
              </div>

              <div v-else-if="b.type === 'heading'">
                <div v-if="b.value === 'Inquiry Button'">
                  <br/>
                  <router-link class="btn btn-primary btn-lg" to="/contact-us/server">INQUIRY/SETUP ACCOUNT</router-link>
                  <br/><br/>
                </div>
                <div v-else>
                  <h4>{{b.value}}</h4>
                </div>
              </div>

              <div v-else-if="b.type === 'paragraph'" class="paragraph">
                <p v-html="b.value"></p>
              </div>

              <div v-else-if="b.type === 'image'" class="padb">
                <img
                :src="b.value.large.src"
                :width="b.value.large.width"
                :height="b.value.large.height">
              </div>

            </div>
          </div>

          <!-- end of page content -->
        
  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'DinoEdge',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Edge Solutions", link: "EdgeSolutions"})
    breadcrumb_links.value.push({text: "Edge Computing for Health & Safety", link: "DinoEdge"})

    getAPI
    .get('/content/pages/59')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.clear {clear:both;}
.padl {margin-left:50px;}
.pad {margin-right:50px;}
.padb {margin-bottom:50px;}
.paragraph {margin:30px 0;}
.underline {border-bottom:1px solid #888;}

.title {padding:80px 0 0 20px; }
.title_img1 {padding-top:40px;}
</style>